import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column } from 'components/atoms';

const TextCaseStudyNew = ({ data, className }) => {
  const { content, background_fill, background_color } = data;

  const backgroundColorStyle = background_fill
    ? {
        backgroundColor: `${background_color}`,
      }
    : {};

  return (
    <Section
      className={cn(
        'text-case-study-new',
        { 'case-study_bg-fill': background_fill },
        [className]
      )}
      style={backgroundColorStyle}
    >
      <Container>
        <Row>
          <Column
            xl="8"
            lg="10"
            md="10"
            className="offset-xl-2 offset-md-1 offset-lg-1 text-case-study-new__container"
          >
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="text-case-study-new__content"
            ></div>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

TextCaseStudyNew.defaultProps = {
  className: '',
};

TextCaseStudyNew.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default TextCaseStudyNew;
